<template>
  <div class="punch-index">
<!--    <h3 class="model-title">土场记录</h3>-->
    <div style="padding: 16px 24px 0px;color: #333;font-size: 15px;position: relative;top: -5px;">企业档案信息</div>
    <div class="page-content">
      <van-form @submit="onSubmit" @failed="failed" :label-width="labelWidth" label-align="right" ref="checkForm">
        <div class="form-item">
          <span class="label-tips">*</span>
          <van-field
              v-model="form.name"
              name="userName"
              label="企业名称"
              placeholder="企业名称"
              :rules="[{ required: true, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              v-model="form.signName"
              name="signName"
              label="店招名称"
              placeholder="店招名称"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              v-model="form.director"
              name="director"
              label="负责人"
              placeholder="请输入负责人"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              readonly
              clickable
              name="phone"
              label="负责人电话"
              placeholder="请输入负责人电话"
              :value="form.contact1"
              :rules="[{ validator:validatorPhone,required: false, message: '填入正确的手机号'}]"
              @touchstart.native.stop="phoneShow = true"
          />
          <van-number-keyboard
              get-container="#app"
              v-model="form.contact1"
              :show="phoneShow"
              z-index="9998"
              :maxlength="11"
              @blur="phoneShow = false"
          />
        </div>
        <div class="form-item">
          <van-field
              v-model="form.legalPerson"
              name="legalPerson"
              label="法人代表"
              placeholder="请输入法人代表"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              readonly
              clickable
              name="phone"
              label="法人电话"
              placeholder="请输入法人电话"
              :value="form.legalPersonPhone"
              :rules="[{ validator:validatorPhone,required: false, message: '填入正确的手机号'}]"
              @touchstart.native.stop="phoneShow1 = true"
          />
          <van-number-keyboard
              get-container="#app"
              v-model="form.legalPersonPhone"
              :show="phoneShow1"
              z-index="9999"
              :maxlength="11"
              @blur="phoneShow1 = false"
          />
        </div>
        <div class="form-item">
          <van-field
              v-model="form.telephone"
              name="telephone"
              label="固定电话"
              placeholder="请输入固定电话"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>

        <div class="form-item">
          <span class="label-tips">*</span>
          <van-field
              readonly
              clickable
              name="type"
              :value="show.type"
              @click="showList('cultureDist','type')"
              label="业务范围"
              placeholder="点击选择业务范围"
              :rules="[{ required: true, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <span class="label-tips">*</span>
          <van-field
              readonly
              clickable
              name="proCode"
              :value="show.street"
              @click="showList('streetJson','street')"
              label="所属街道"
              placeholder="点击选择所属街道"
              :rules="[{ required: true, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              readonly
              clickable
              name="street"
              :value="show.officeId"
              @click="showList('officeJson','officeId')"
              label="管理科室"
              placeholder="点击选择管理科室"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="2"
              autosize
              name="address"
              v-model="form.address"
              label="地址"
              placeholder="请输入地址"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              v-model="form.area"
              name="area"
              label="营业面积"
              placeholder="请输入营业面积"
              type="number"
          >
          </van-field>
        </div>
        <div class="form-item">
          <van-field
              v-model="form.fax"
              name="fax"
              label="传真"
              placeholder="请输入传真"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
<!--          <span class="label-tips">*</span>-->
          <van-field
              v-model="form.permit"
              name="permit"
              label="营业执照号码"
              placeholder="请输入营业执照号码"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
<!--          <span class="label-tips">*</span>-->
          <van-field
              v-model="form.license"
              name="license"
              label="许可/备案编号"
              placeholder="请输入许可/备案编号"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
<!--          <span class="label-tips">*</span>-->
          <van-field
              readonly
              clickable
              @click="openDateChose('licenseStart')"
              name="licenseStart"
              :value="form.licenseStart"
              label="批准/登记时间"
              placeholder="点击选择批准/登记时间"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
<!--          <span class="label-tips">*</span>-->
          <van-field
              readonly
              clickable
              @click="openDateChose('licenseEnd')"
              name="licenseEnd"
              :value="form.licenseEnd"
              label="到期时间"
              placeholder="点击选择到期时间"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field name="form.picture" label="企业证照（上传照片）" :rules="[{ required: false, message: '至少上传一张证照' }]">
            <template #input>
              <van-uploader v-model="fileTest"  capture="camera" :before-read="beforeRead" :upload-text="'拍照上传'" :after-read="sendFile" />
            </template>
          </van-field>
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="3"
              autosize
              name="safetyResponseInfo"
              v-model="form.safetyResponseInfo"
              label="行业检查、疫情防控责任制"
              placeholder="请输入行业检查、疫情防控责任制"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="3"
              autosize
              name="safetyManageInfo"
              v-model="form.safetyManageInfo"
              label="行业检查、疫情防控管理制度"
              placeholder="请输入行业检查、疫情防控管理制度"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="3"
              autosize
              name="safetyPerson"
              v-model="form.safetyPerson"
              label="行业检查、疫情防控管理组织机构和管理人员"
              placeholder="请输入行业检查、疫情防控管理组织机构和管理人员"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="3"
              autosize
              name="personCertificate"
              v-model="form.personCertificate"
              label="消防值班人员、救生员、教练员等持证情况"
              placeholder="请输入消防值班人员、救生员、教练员等持证情况"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="3"
              autosize
              name="personCertificate"
              v-model="form.deviceInfo"
              label="大型、特种设备管理情况"
              placeholder="请输入大型、特种设备管理情况"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="3"
              autosize
              name="safetyPlan"
              v-model="form.safetyPlan"
              label="行业检查、疫情防控、应急预案建立和演练情况"
              placeholder="请输入行业检查、疫情防控、应急预案建立和演练情况"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <div class="form-item">
          <van-field
              type="textarea"
              rows="3"
              autosize
              name="accidentInfo"
              v-model="form.accidentInfo"
              label="事故事件调查处理情况"
              placeholder="请输入事故事件调查处理情况"
              :rules="[{ required: false, message: '必填'}]"
          />
        </div>
        <van-field name="switch" label="是否关停">
          <template #input>
            <van-switch v-model="form.closed" size="20" />
          </template>
        </van-field>
        <div style="padding: 32px 32px 20px;">
          <van-button  size="large" :loading="loading"   type="info" native-type="submit" >保存</van-button>
        </div>

      </van-form>
    </div>
    <van-popup v-model="successPop" position="right" :style="{ height: '100%',width:'100%' }">

      <div >
<!--        <div class="model-title">
          <van-icon name="arrow-left" size="28" />
        </div>-->
        <div class="success-icon">
          <van-icon name="passed" color="#00E68F" size="40" />
        </div>
        <div class="success-content">提交成功，{{seconds}}s<a href="javascript:void(0)" @click="resetForm">关闭</a>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="codePicker" position="bottom">
      <van-search v-model="searchkey" placeholder="请输入搜索关键词" />
      <van-picker
          show-toolbar
          :columns="pickerList"
          value-key="name"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
    <van-calendar get-container="#app" v-model="calendarShow"
                   @confirm="onConfirmDate"
                  :min-date="minDate"
                  :style="{ height: '500px',position:'fixed',bottom:'0' }"/>
  </div>
</template>

<script>
import {formatDate} from "@/api/time"
import {StreetJson,OfficeJson,RenderCulturalDict} from "@/api/dict"
import {
  Button, Col,Calendar,
  NumberKeyboard,Row,Uploader,Switch,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Picker,Search,Popup
} from 'vant';
import Compressor from 'compressorjs';
export default {
  name: "index",
  components:{
    [Switch.name]: Switch ,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Calendar.name]: Calendar,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [NumberKeyboard.name]: NumberKeyboard,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Picker.name]: Picker,
    [Search.name]: Search,
    [Col.name]: Col,
    [Row.name]: Row
  },
  computed:{

  },
  data(){
    return {
      labelWidth:84,
      show:{
        code:undefined,
        name:"",
        companyId:"",
        plate:"",
        driver:"",
        recordType:"",
        recordPlace:"",
        recorder:"",
        mode:1,
        recordTime:"",
        picture:""
      },
      cultureDist:{},
      form:{
        "id": "",
        "name": "",
        "signName": "",
        "address": "",
        "officeId": "",
        "telephone": "",
        "legalPerson": "",
        "legalPersonPhone": "",
        "director": "",
        "contact1": "",
        "contact2": "",
        "fax": "",
        "area": 0,
        "street": "",
        "permit": "",
        "license": "",
        "licenseStart": null,
        "licenseEnd": null,
        "scope": "",
        "type": "1",
        "enterpriseNature": "",
        "remark": "",
        "closed": false,
        "status": "0",
        "createTime": null,
        "safetyManager": null,
        "safetyManagerPhone": null,
        "epidemicPerson": null,
        "epidemicPersonPhone": null,
        "files": null,
        "safetyResponseInfo": null,
        "safetyManageInfo": null,
        "safetyPerson": null,
        "personCertificate": null,
        "deviceInfo": null,
        "safetyPlan": null,
        "accidentInfo": null,
        "position": []
      },
      calendarShow:false,
      phoneShow:false,
      phoneShow1:false,
      readOnly:false,
      loading:false,
      pickerKey:"",
      confirmKey:"",
      pickerList:[],
      searchkey:"",
      codePicker:false,
      dateKey:"",
      fileTest:[],
      successPop:false,
      seconds:5,
      timer:null,
      streetJson:'',
      officeJson:'',
      minDate: new Date(2021, 9, 1),
      maxDate: new Date(2030, 9, 1)
    }
  },
  methods:{
    init(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/user/company/" + localStorage.getItem("wljptuser"),
          method: "get"
        }).then(res=>{
          console.log(res,"company");
            if(res.data.success){
              this.form = res.data.data;
              if(this.form.street && this.streetJson[this.form.street]){
                this.show.street = this.streetJson[this.form.street].name;
              }else{
                this.show.street = this.form.street;
              }
              if(this.form.officeId && this.officeJson[this.form.officeId]){
                this.show.officeId = this.officeJson[this.form.officeId].name;
              }else{
                this.show.officeId = this.form.officeId;
              }
              if(this.form.type && this.cultureDist[this.form.type]){
                this.show.type = this.cultureDist[this.form.type].name;
              }else{
                this.show.type = this.form.type;
              }
              if(this.form.files){
                this.fileTest = this.form.files.split(",").map(f=>{
                  let isImage = false;
                  if(f.indexOf('png')>-1 || f.indexOf('jpg')>-1 || f.indexOf('jpeg')>-1 || f.indexOf('gif')>-1 ||f.indexOf('bmp')>-1 || f.indexOf('webp')>-1){
                    isImage = true
                  }
                  return {
                    url:f,
                    isImage:isImage
                  }
                })
              }else {
                this.fileTest = [];
              }


            }else{
              Notify({ type: 'warning', message: "获取用户企业失败" });
            }
          resolve(true);
        }).catch(error=>{
          Notify({ type: 'warning', message: error });
        })
      })

    },
    openDateChose(key){
      this.dateKey = key;
      this.calendarShow = true;
    },
    //onConfirmDate
    onConfirmDate(value){
      console.log(this.dateKey,"key")
      this.form[this.dateKey] = formatDate(value);
      this.calendarShow = false;
    },
    //打开选择框
    showList(list, key) {
        this.pickerList = Array.isArray(this[list]) ? this[list] : Object.values(this[list]);
        this.pickerKey = list;
        this.confirmKey = key;
        this.codePicker = true;
    },
    // 获取企业类型
    getCulturalDict(){
      return new Promise(resolve => {
        RenderCulturalDict((data) => {
          data.map(item=>{
            this.cultureDist[item.id] = item;
          })
          resolve(true)
        })
      })
    },
    onSubmit(values){

      this.form.files = this.fileTest.map(pic=>{
        return pic.url;
      }).join(",");
      this.loading = true;
      this.$http({
        url: "/zuul/cultural/",
        method: "post",
        data:this.form
      }).then(res=>{
        if(res.data.success){
          this.successTip();
        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
        this.loading = false;
      })



    },
    resetForm(){

      /*this.form = {
        code:undefined,
        name:undefined,
        companyId:undefined,
        plate:undefined,
        driver:undefined,
        recordType:undefined,
        recorder:localStorage.getItem("punchUserId"),
        mode:1,
        recordTime:"",
        picture:""
      }
      this.show = {
        code:undefined,
        name:undefined,
        companyId:undefined,
        plate:undefined,
        driver:undefined,
        recordType:undefined,
        mode:1,
        recordTime:"",
        recorder:this.form.recorder?this.operatorJson[this.form.recorder]:"",
        picture:""
      }
      this.form.recordPlace = localStorage.getItem("punchUserSoilId");
      this.show.recordPlace = this.form.recordPlace?this.soilJson[this.form.recordPlace]:"";
      if(this.operatorType[this.form.recorder]=="1"){
        this.form.recordType = "2";
      }else{
        this.form.recordType = "3";
      }
      this.fileTest = [];*/
      this.successPop = false;
    },
    successTip(){
      this.successPop = true;
      this.seconds = 5;
      this.timer = setInterval(() => {
        this.seconds--;
        if(this.seconds<0){
          this.resetForm();
          window.clearInterval(this.timer);
        }
      }, 1000);
    },
    failed(rule){
      if(rule.errors && rule.errors.length>0){
        this.$refs.checkForm.scrollToField(rule.errors[0].name)
      }
    },
    //验证手机号
    validatorPhone(val, rule) {
      if (!(/^1[3456789]\d{9}$/.test(val))) {
        return false;
      }
      return true;
    },
    // 确认项目
    onConfirm(value){
      console.log(value,"confirm",this.confirmKey)
      this.form[this.confirmKey] = value.code?value.code:value.id;
      this.show[this.confirmKey] = value.name;
      this.codePicker = false;
    },
    beforeRead(file,defaultQuqlity=0.6) {
      if(file.type.indexOf('image/') >-1){
        let fileSize = file.size/1024/1024;
        let quality = defaultQuqlity;
        if(fileSize<0.5){
          quality = 1;
        }else if(fileSize<1){
          quality = 0.8
        }else if(fileSize<2){
          quality = 0.6
        }else if(fileSize<4){
          quality = 0.3   // 0.4 0.24
        }else if(fileSize<6){
          quality = 0.15  // 0.1 0.06   0.2-0.17
        }else if(fileSize<8.1){
          quality = 0.1
        }else{
          return new Promise((resolve,reject)=>{
            Toast('文件大小不能超过 8M');
            reject({
              msg:"图片不能超过6M"
            })

          })
        }

        return new Promise((resolve) => {
          // compressorjs 默认开启 checkOrientation 选项
          // 会将图片修正为正确方向
          new Compressor(file, {
            quality: quality,
            success(result){
              resolve(result);
            },
            error(err) {
              console.log(err.message);
            },
          });
        });
      }else {
        return file;
      }

    },
    sendFile(file){
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name,file.file);
      let isImage = (file.file.type.indexOf("image/") > -1) ? true : false;
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{
        if(res.data.success){
          this.fileTest[(this.fileTest.length>0)?(this.fileTest.length-1):0].url = '/zuul' + (isImage?(res.data.data[0].viewUrl):(res.data.data[0].downloadUrl))
        }
      })
    },
    listResolve(){
      let arr = [];
      if (this.searchkey.length > 0) {
        arr = this[this.pickerKey].filter(item => {
          if (item["name"].indexOf(this.searchkey) > -1) {
            return item
          }
        })
      } else {
        arr = this[this.pickerKey];
      }
      this.pickerList = arr;
    }
  },
  watch:{
    searchkey: {
      handler(n, o) {
        this.listResolve()
      },
      deep: true
    }
  },
  mounted(){
    this.streetJson = StreetJson;
    this.officeJson = OfficeJson;
    let _this = this;
    Promise.all([this.getCulturalDict()]).then(res=>{
      _this.init();
    })

  },
  beforeDestroy() {
    if(this.timer){
      window.clearInterval(this.timer);
    }
  }
}
</script>

<style scoped lang="less">
.punch-index /deep/ .van-field__control{
  padding-left:10px;
}
.punch-index{
  .page-content{
    background: white;
    padding-top: 16px;

    .form-item{
      position: relative;
      padding: 0px 16px;

      .label-tips{
        position: absolute;
        color: red;
        top: 16px;
        left: 18px;
        z-index: 1;
      }
    }

    .info-btn{
      position: fixed;
      bottom: 50px;
      left: 0px;
      margin: 0px 24px;
    }

  }

  .success-icon{
    text-align: center;
    position: relative;
    top: calc(50vh - 100px);
  }
  .success-content{
    padding: calc(50vh - 80px) 20px 0px 20px;
    text-align: center;
  }
}
</style>
